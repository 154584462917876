import { BrowserRouter,Routes, Route, Link  } from "react-router-dom";
import Home from "./pages/home"
function App() {

  return (
    <div className="App">
   <BrowserRouter >
   <Routes>
        <Route path="/" element={<Home />} />
        
        <Route path="*" element={<div>test3</div>} />
      </Routes>
   </BrowserRouter>
    </div>
  );
}

export default App;
