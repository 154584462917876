import React from 'react'
import { Link } from 'react-router-dom'
import WhiteBg from "../../assets/Navbar/white.png"
import Style from "./style.module.css"
import { MenuAlt3Icon } from '@heroicons/react/solid'
import { Popover,Transition  } from '@headlessui/react'
export default function Navbar() {
    return (
        <>
        <nav className="md:block hidden justify-center py-5 z-10  flex px-14 sticky">
            <div className="mr-32">
                <img src={WhiteBg} className="h-14 "  alt="LOGO" />
            </div>
            <div className="my-auto">
            <a className={`${Style.underlineHoverEffect} mx-4 text-white text-xl font-semibold`} href="#Services">Services</a>
            <a className={`${Style.underlineHoverEffect} mx-4 text-white text-xl font-semibold`}   href="#Showcase">Showcase</a>
            <a className={`${Style.underlineHoverEffect} mx-4 text-white text-xl font-semibold`} href="#Technology ">  Technology </a>
            <a className={`${Style.underlineHoverEffect} mx-4 text-white text-xl font-semibold`}   href="#contact">Contact Us</a>
         

            </div>
           
        </nav>

        <nav className="md:hidden py-3 px-3 flex justify-between relative">
        <div className="mr-32">
                <img src={WhiteBg} className="h-9 "  alt="LOGO" />
            </div>
            <Popover className=" z-20">
      <Popover.Button>   <MenuAlt3Icon className="h-7 text-white  cursor-pointer" /></Popover.Button>
   

      <Popover.Panel className="absolute top-16 left-0 z-10 bg-white w-full">
        <div className="grid grid-cols-1">
        <a className={`${Style.underlineHoverEffect} mx-4 text-gray-700  font-semibold`} href="#Services">Services</a>
            <a className={`${Style.underlineHoverEffect} mx-4 text-gray-700  font-semibold`}   href="#Showcase">Showcase</a>
            <a className={`${Style.underlineHoverEffect} mx-4 text-gray-700  font-semibold`} href="#Technology ">  Technology </a>
            <a className={`${Style.underlineHoverEffect} mx-4 text-gray-700  font-semibold`}   href="#contact">Contact Us</a>
        </div>

        <img src="/solutions.jpg" alt="" />
      </Popover.Panel>
    
    </Popover>
        </nav>
        
     </>

    )
}
