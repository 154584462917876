import React from 'react'
import Style from "./style.module.css"

export default function Cards(props) {
    return (
        <div className="bg-gray-100 border transition-colors delay-200 hover:bg-white border-gray-200 px-5 py-8 my-5 md:my-2  mx-4 rounded-tl-3xl rounded-br-3xl shadow-xl">
            <h2 className="font-bold text-lg md:text-xl">{props.title}</h2>
           <p className="tracking-wide text-base text-gray-700">{props.description}</p> 
           <div className={`${Style.iconContainer} relative ml-5`}>
               {props.icon}
               
               <div className={`${Style.blob} `} style={{background: props.color}}></div>
               </div>

        </div>
    )
}
